import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FAQAccordian from '../../../components/FAQAccordian/FAQAccordian';
import Fade from '@material-ui/core/Fade';
import data from './data/artist-faq.json'

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import classNames from 'classnames';

const artistFaqArray = data.ArtistFaqArray;

const useStyles = makeStyles({
    ...styles, 
    title: {
        ...styles.title,
        color: '#fff',
        textAlign: 'center',
    },
});

const ArtistFaqSection = () => {
    const classes = useStyles();
    return (
        <Container>
            <Fade in={true} timeout={1000} className={classes.root}>
                <Box my={20}>
                    <h2 className={classNames(classes.title, classes.centered)}>FAQ for artists interested in collaborating</h2>
                    {artistFaqArray.map((qanda) => (FAQAccordian(qanda)))}
                </Box>
            </Fade>
        </Container>
    );
}

export default ArtistFaqSection;