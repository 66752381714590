import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import image1 from "assets/img/stock-art.jpg";
import image2 from "assets/img/stock-blockchain.jpg";
import image3 from "assets/img/olives_small.jpg";

const useStyles = makeStyles({
  ...styles,
  aboutImage: {
    height: '400px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '15px',
  },
  cardMobileSpacing: {
    "@media (max-width: 400px)": {
      margin: '30px 1px 1px',
  }
  }
});

export default function AboutSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} className={classes.cardMobileSpacing}>
            <div>
              <div style={{
                backgroundImage: 'url(' + image1 + ')'
              }} className={classes.aboutImage} />
              <InfoArea
                title="ART"
                description="10 artists from around the world are designing pieces that will be the physical label for the bottle and an accompanying digital asset."
                icon={''}
                iconColor="success"
                vertical
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.cardMobileSpacing}>
            <div>
              <div style={{
                backgroundImage: 'url(' + image2 + ')'
              }} className={classes.aboutImage} />
              <InfoArea
                title="BLOCKCHAIN"
                description="Each bottle is represented on the Ethereum blockchain by an NFT that allows the owner to claim delivery & ownership of the bottle, access the digital art, and access a personalized video capturing the experience of a traditional harvest"
                icon={''}
                iconColor="success"
                vertical
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.cardMobileSpacing}>
            <div>
              <div style={{
                backgroundImage: 'url(' + image3 + ')'
              }} className={classes.aboutImage} />
              <InfoArea
                title="OLIVE OIL"
                description="Harvested from an orchard containing 300 year old olive trees in the region of Messinia, Greece.
                Certified organic extra-virgin crafted by traditional cold-press technique."
                icon={''}
                iconColor="success"
                vertical
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
