import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './store/store';
import ReduxToastr from 'react-redux-toastr';

import "assets/scss/material-kit-react.scss?v=1.10.0";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "assets/css/index.css";

// pages for this product
import HomePage from "views/HomePage/HomePage.js";
import ArtistsPage from "views/ArtistsPage/ArtistsPage.js";
import ShippingPage from "views/ShippingPage/ShippingPage.js";
import ScanPage from "views/ScanPage/ScanPage.js";
import DetailsPage from "views/DetailsPage/DetailsPage";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/details/:hash?" component={DetailsPage} />
        <Route path="/artists" component={ArtistsPage} />
        <Route path="/shipping" component={ShippingPage} />
        <Route path="/scan" component={ScanPage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </ConnectedRouter>
    <ReduxToastr
        timeOut={6000}
        newestOnTop={true}
        position="bottom-right"
        closeOnToastrClick
      />
  </Provider>,
  document.getElementById("root")
);
