import React, {useState} from "react";
import { useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import logo from "assets/img/logo.png";

import image from "assets/img/artists-bg.jpg";

import QrReader from 'react-qr-scanner'

const useStyles = makeStyles({
  ...styles,
  container: {
    ...styles.container,
    paddingTop: '200px',
  },
  logoFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '40px',
    imageRendering: "pixelated",
    marginRight: '10px',
  },
  brand: {
    margin: '0',
    fontWeight: '500',
  },
});

export default function ScanPage(props) {
  const auth = useSelector(state => state.auth);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  const { ...rest } = props;

  const handleScan = (data) => {
      console.log(data);
  }

  const handleError = (err) => {
    console.log(err);
}
  return (
    <div>
      <Header
        color="transparent"
        brand={<div className={classes.logoFrame}>
          <img src={logo} className={classes.logo}/>
          <h4 className={classes.brand}>Aisthisi</h4>
        </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
            <div className={classes.container}>
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.customMain}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="success" className={classes.cardHeader}>
                    <h3><b>QR Scan</b></h3>
                    <p>Scan the QR Code from the bottle you received.</p>
                  </CardHeader>
                  <CardBody>
                    <QrReader
                        delay={100}
                        style={{
                            height: 'auto',
                            width: '100%',
                            minHeight: '500px',
                        }}
                        onError={handleError}
                        onScan={handleScan}
                        />
                  </CardBody>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
            </div>
        </div>
      <Footer />
    </div>
  );
}
