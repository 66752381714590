import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
// import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import image1 from "assets/img/landing-olives.jpg";
import image2 from "assets/img/artists-bg.jpg";
import imageTeaser from "assets/img/oil_qr_code.png";
import logo from "assets/img/logo.png";


// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import AboutSection from "./Sections/AboutSection.js";
import CollectionSection from "./Sections/CollectionSection.js";

import { Hidden } from "@material-ui/core";
import Favorite from '@material-ui/icons/Favorite';

const useStyles = makeStyles({
  ...styles,
  bigtitle: {
    ...styles.title,
    fontSize: '4rem',
    marginBottom: '0',
  },
  sliderImage: {
    height: '400px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  logoFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '40px',
    imageRendering: "pixelated",
    marginRight: '10px',
  },
  brand: {
    margin: '0',
    fontWeight: '500',
  },
  section: {
    padding: '70px 0',
    textAlign: 'center',
  },
  aboutTitle: {
    ...styles.title,
    textAlign: 'center',
  },
  imageSize: {
    maxHeight: '200px'
  },
  collection: {
    backgroundImage: 'url(' + image2 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    position: 'relative',
    '&::before': {
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      content: "''",
      display: 'block',
      zIndex: '0',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.5)'
    },
  }
});

export default function HomePage(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  const { ...rest } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true
  };

  const scrollTopCollection = () => {
    document.getElementById('collection').scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <div>
      <Header
        color="transparent"
        brand={<div className={classes.logoFrame}>
          <img src={logo} className={classes.logo}/>
          <h4 className={classes.brand}>Aisthisi</h4>
        </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-olives.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.bigtitle}>Aisthisi</h1>
              <h2 className={classes.title}>
                Art + Blockchain + Olive Oil
              </h2>
              <h4>
              10 unique proof-of-harvest bottles of organic Greek deliciousness
              </h4>
              <br />
              <br />
              <br />
              <Button
                color="success"
                size="lg"
                onClick={() => scrollTopCollection()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Show Collection
              </Button>
            </GridItem>
            <Hidden mdDown>
              <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
                
                    <div>
                      
                      
                      <div className="slick-caption" style={{
                            width: '100%',
                            margin: '0',
                            padding: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            padding: '20px',
                            "text-align": 'center',
                            background: 'rgb(255,255,255)',
                            background: '-moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%)',
                            background: '-webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%)',
                            background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%)',
                            filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFF",endColorstr="#AAAAAA",GradientType=1)',
                      }}>
                        <img src={imageTeaser} />
                        <Button color="info" onClick={() => {window.location.href='https://rarible.com/aisthisi-nft'}} fullWidth="true" round><Favorite /> Genesis Harvest Dropping Right Now</Button>
                      </div>
                    </div>
              </GridItem>
            </Hidden>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <ProductSection />
          <AboutSection />
        </div>
        <div id="collection" className={classNames(classes.section, classes.collection)}>
          <div className={classes.container}>
              <GridContainer align="center" justify="center" alignItems="center">
                <GridItem align="center" justify="center" alignItems="center" xs={10} sm={10} md={10}>
                    <a href="https://rarible.com/aisthisi-nft"><h2 className={classes.aboutTitle}>Genesis Harvest dropping now</h2></a>
                </GridItem>
                <GridContainer align="center" justify="center" alignItems="center" xs={10} sm={10} md={10}>
                <CollectionSection />
                </GridContainer>
              </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
