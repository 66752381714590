import {
    CONNECT_WALLET_PENDING,
    CONNECT_WALLET_SUCCESS,
    CONNECT_WALLET_ERROR,
    LOGOUT_WALLET,
} from './auth.action';

const INITIAL_STATE = {
  eth_address: '',
  web3: null,
  provider: null,
  sdk: null,
  connection_error: '',
  wallet_type: '',
  token_instance: null,
  busy: {
    metamask: false,
    fortmatic: false,
    portis: false,
    walletConnect: false,
    walletLink: false,
    zero: false,
  },
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONNECT_WALLET_PENDING:
      return {
        ...state,
        busy: {
            ...state.busy,
            [action.wallet_type]: true,
        }
      };
    case CONNECT_WALLET_SUCCESS:
        return {
            ...state,
            eth_address: action.eth_address,
            web3: action.web3,
            provider: action.provider,
            connection_error: '',
            wallet_type: action.wallet_type,
            token_instance: action.token_instance,
            network_id: action.network_id,
            busy: {
                ...state.busy,
                [action.wallet_type]: false,
            }
        };
    case CONNECT_WALLET_ERROR:
        return {
            ...state,
            eth_address: '',
            provider: null,
            sdk: null,
            wallet_type: action.wallet_type,
            connection_error: action.error,
            token_instance: null,
            network_id: null,
            busy: {
                ...state.busy,
                [action.wallet_type]: false,
            }
        };
    case LOGOUT_WALLET:
        return INITIAL_STATE;
    default:
      return state;
  }
};

export default auth;