import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import Amanda from "assets/img/artists/Amanda.jpg";
import Ania from "assets/img/artists/Ania.jpg";
import Antonio from "assets/img/artists/Antonio.jpg";
import David from "assets/img/artists/David.png";
import Francisd from "assets/img/artists/Francisd.jpg";
import MajorArt from "assets/img/artists/MajorArt.jpg";
import Stephan from "assets/img/artists/Stephan.jpg";
import Tashi from "assets/img/artists/Tashi.jpg";
import Will from "assets/img/artists/Will.jpg";
import RelativeBilanz from "assets/img/artists/relativebalanz.jpg";

const useStyles = makeStyles({
  ...styles,
  section: {
    ...styles.section,
    color: '#FFF !important',
    padding: '0',
  },
  title: {
    ...styles.title,
    color: '#FFF !important',
  },
  cardBody: {
    ...styles.cardBody,
    color: '#FFF !important',
    zIndex: '1',
  },
  cardTitle: {
    ...styles.cardTitle,
    color: '#FFF !important',
    zIndex: '1',
    textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
  },
  smallTitle: {
    ...styles.smallTitle,
    color: '#FFF !important',
    zIndex: '1',
  },
  description: {
    ...styles.description,
    color: '#FFF !important',
    zIndex: '1',
  },
  customCard: {
    borderRadius: '15px',
    boxShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.2)',
    fontSize: '2rem',
    overflow: 'hidden',
    position: 'relative',
    padding: '30px',
    boxSizing: 'border-box',
    margin: '20px auto',
    width: '90%',
    minHeight:'305px',
    backgroundSize: 'cover',
    '&:before': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(5px) saturate(100%) contrast(100%) brightness(100%)',
      content: "''",
      height: '100%',
      position: 'absolute',
      width: '100%',
      left: '0',
      top: '0',
    }
  },
});

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Artists</h2>
      <h4 className={classes.subtitle}>Meet our awesome artists.</h4>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Ania} alt="Ania" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                4ni4 Shestakova
                <br />
                <small className={classes.smallTitle}>Moscow</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="https://linktr.ee/4ni4"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Amanda} alt="Amanda" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Amanda Åkerman
                <br />
                <small className={classes.smallTitle}>Gothenburg</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="http://www.amandaakerman.com/"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Antonio} alt="Ania" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Antonio Ciutto
                <br />
                <small className={classes.smallTitle}>South Africa</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="http://antoniociutto.com/"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={David} alt="Ania" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                David Wojtowycz
                <br />
                <small className={classes.smallTitle}>Lisbon</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="http://www.competitivemachines.com/"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Francisd} alt="FrancisD" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                FrancisD.
                <br />
                <small className={classes.smallTitle}>Phoenix</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="https://linktr.ee/francisdayton"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={MajorArt} alt="Major Art" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Major Art
                <br />
                <small className={classes.smallTitle}>Estonia</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="https://linktr.ee/majorart"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={RelativeBilanz} alt="relativebalanz" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                RelativeBilanz
                <br />
                <small className={classes.smallTitle}>Berlin</small>
              </h4>
                <div className={classes.centered}>
                <Button
                  color="success"
                  className={classes.margin5}
                  href="https://www.instagram.com/relativebilanz/"
                  target="_blank"
                >
                  Profile
                </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Stephan} alt="Stephan Weixler" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Stephan Weixler
                <br />
                <small className={classes.smallTitle}>Graz</small>
              </h4>
                <div className={classes.centered}>
                <Button
                  color="success"
                  className={classes.margin5}
                  href="https://www.stephanweixler.com/"
                  target="_blank"
                >
                  Profile
                </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Tashi} alt="Tashi Fay" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Tashi Fay
                <br />
                <small className={classes.smallTitle}>New York</small>
              </h4>
                <div className={classes.centered}>
                <Button
                  color="success"
                  className={classes.margin5}
                  href="https://www.tashifay.com/"
                  target="_blank"
                >
                  Profile
                </Button>
                </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain className={classes.customCard} >
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Will} alt="Will Benedict" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Will Benedict
                <br />
                <small className={classes.smallTitle}>Paris</small>
              </h4>
                <div className={classes.centered}>
                  <Button
                    color="success"
                    className={classes.margin5}
                    href="https://www.instagram.com/rainingbodies"
                    target="_blank"
                  >
                    Profile
                  </Button>
                </div>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
