/*eslint-disable*/
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import headerStyles from "./Header.module.scss";
import { connectWalletPending } from "store/auth/auth.action";
import { logoutWalletPending } from "store/auth/auth.action";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const auth = useSelector(state => state.auth)

  const connectWallet = (wallet_type) => {
    dispatch(connectWalletPending(wallet_type));
  }

  const logoutWallet = () => {
    dispatch(logoutWalletPending());
  }

  useEffect(() => {
    if (auth.provider) {
      auth.provider.on("chainChanged", async () => {
        dispatch(connectWalletPending('metamask'));
      });
  
      auth.provider.on("accountsChanged", async () => {
        dispatch(connectWalletPending('metamask'));
      });
    }
  }, [auth]);

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/"
          color="transparent"
          className={classes.navLink}
        >
          Concept
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/artists"
          color="transparent"
          className={classes.navLink}
        >
          Artists
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/shipping"
          color="transparent"
          className={classes.navLink}
        >
          Shipping
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/aisthisi_nft"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} /> Twitter
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-discord"
          title="Join us on discord"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://discord.com/invite/HNhDTMTAN7"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-discord"} /> Discord
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on Instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/aisthisi_nft/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} /> Instagram
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="instagram-medium"
          title="Follow us on medium"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://medium.com/aisthisi"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-medium"} /> Blog
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        {!auth.eth_address && <Tooltip
          id="connect-wallet"
          title="Connect to your wallet"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="success"
            onClick={() => connectWallet('metamask')}
          >
            Connect Wallet
          </Button>
        </Tooltip>}
        <div>
          {auth.eth_address && 
            <div className={headerStyles.userData}>
              <div className={headerStyles.network}>
                <p>{auth.eth_address.slice(0,3) + '...' + auth.eth_address.slice(auth.eth_address.length - 3, auth.eth_address.length)}</p>
                <p><small>{auth.network_id == 1 ? 'Mainnet' : 'Testnet'}</small></p>
              </div>
              <Tooltip
                title="Logout"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  onClick={() => logoutWallet()}
                  size="sm"
                >
                  Logout
                </Button>
              </Tooltip>
            </div>
          }
        </div>
      </ListItem>
    </List>
  );
}
