import Web3 from 'web3';

export const connectToWallet = async (wallet_type) => {
    if (!wallet_type) return { error: 'Cannot connect to the wallet.' };

    if (wallet_type === 'metamask') {
        const win = window;
        const provider = win.ethereum;

        if (!provider || !provider.isMetaMask) {
			return { error: 'Metamask plugin is not installed.' };
		}

        const web3 = new Web3(provider);

		const result = await provider.request({ method: "eth_chainId" });

        if (Number(result) !== Number(process.env.REACT_APP_CHAIN_ID) && Number(result) !== Number(process.env.REACT_APP_TESTNET_ID)) {
			return { error: 'Wrong Network.' };
		}

        try {
			await provider.request({ method: "eth_requestAccounts" });
		} catch (err) {
            return { error: 'Error retreiving account data from metamask.' };
		}

		return { provider, web3, sdk: null };
    }

    return { error: 'Wrong wallet provider.' };
}
