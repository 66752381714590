export const CONNECT_WALLET_PENDING = 'CONNECT_WALLET_PENDING';
export const CONNECT_WALLET_SUCCESS = 'CONNECT_WALLET_SUCCESS';
export const CONNECT_WALLET_ERROR = 'CONNECT_WALLET_ERROR';

export const LOGOUT_WALLET_PENDING = 'LOGOUT_WALLET_PENDING';
export const LOGOUT_WALLET = 'LOGOUT_WALLET';

export const connectWalletPending = (wallet_type) => {
    return {
        type: CONNECT_WALLET_PENDING,
        wallet_type
    }
}

export const connectWalletSuccess = (wallet_type, eth_address, web3, provider, sdk, token_instance = null, network_id) => {
    return {
        type: CONNECT_WALLET_SUCCESS,
        eth_address,
        web3,
        provider,
        sdk,
        wallet_type,
        token_instance,
        network_id
    }
}

export const connectWalletError = (wallet_type, error) => {
    return {
        type: CONNECT_WALLET_ERROR,
        wallet_type,
        error
    }
}

export const logoutWalletPending = () => {
    return {
        type: LOGOUT_WALLET_PENDING
    }
}

export const logoutWallet = () => {
    return {
        type: LOGOUT_WALLET
    }
}
