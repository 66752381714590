import { cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const teamStyle = {
  section: {
    padding: "0",
    textAlign: "center",
    marginLeft: "0px",
    marginRight: "0px",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    minWidth: "400px",
    marginLeft: "0",
    marginRight: "0",
  },
  '@media (max-width: 600px)': {
    itemGrid: {
      minWidth: "20px",
      maxWidth: "400px",
      marginLeft: "0px",
      marginRight: "0px",
    }
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  // margin5: {
  //   margin: "5px 0",
  // },
};

export default teamStyle;