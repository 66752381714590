import React, {useState,useEffect} from "react";
import { useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import logo from "assets/img/logo.png";

import image from "assets/img/artists-bg.jpg";

import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  ...styles,
  container: {
    ...styles.container,
    paddingTop: '200px',
  },
  logoFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '40px',
    imageRendering: "pixelated",
    marginRight: '10px',
  },
  brand: {
    margin: '0',
    fontWeight: '500',
  },
});

export default function DetailsPage(props) {
  const { hash } = useParams();
  
  const [isOwner, setIsOwner] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [data, setData] = useState(null);

  const auth = useSelector(state => state.auth);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    const updateHash = async () => {
      if (hash && auth.eth_address) {
        setIsChecking(true);
        const data = JSON.parse(JSON.parse(atob(hash)));
  
        if (data && data.tokenId && data.unlockHash) {
          setData(data);
  
          // Check ownership
          let isOwner = false;

          try {
            const ownerAddress = await auth.token_instance.methods.ownerOf(data.tokenId).call();

            if (ownerAddress.toLowerCase() === auth.eth_address.toLowerCase()) isOwner = true;
          } catch (e) {
            console.log(e.message);
          }

          setIsOwner(isOwner);

          // Check if already unlocked
          let isUnlocked = false;

          try {
            isUnlocked = await auth.token_instance.methods.tokenUnlocked(data.tokenId).call();
          } catch (e) {
            console.log(e.message);
          }

          setIsUnlocked(isUnlocked);

          setIsChecking(false);
        }
      }
    }

    updateHash();
  }, [hash, auth]);

  const claimTokens = async () => {
    try {
      const unlockToken = await auth.token_instance.methods.unlockToken(data.unlockHash, data.tokenId).send({from: auth.eth_address});
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div>
      <Header
        color="transparent"
        brand={<div className={classes.logoFrame}>
          <img src={logo} className={classes.logo}/>
          <h4 className={classes.brand}>Aisthisi</h4>
        </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.customMain}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="success" className={classes.cardHeader}>
                    <h3><b>Claim tokens</b></h3>
                    <p>Follow the following steps to claim your tokens.</p>
                  </CardHeader>
                  <CardBody>
                    <div className={classes.step}>
                      1. Check token owenrship ({isChecking ? 'Checking ownership...' : (isOwner ? 'OWNER' : 'NOT OWNER')})
                      <CustomInput
                        labelText="Token Id"
                        id="token_id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: 'token_id',
                          disabled: true,
                          value: data ? data.tokenId : '',
                        }}
                      />
                    </div>
                    <div className={classes.step}>
                      2. Unlock token ({isChecking ? 'Checking lock...' : (isUnlocked ? 'ALREADY UNLOCKED' : 'READY')})
                      <CustomInput
                        labelText="Unlock hash"
                        id="hash"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: 'hash',
                          disabled: true,
                          value: data ? data.unlockHash : '',
                        }}
                      />
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="info" size="lg" onClick={() => claimTokens()} disabled={isUnlocked}>
                      Unlock
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
            </div>
        </div>
      <Footer />
    </div>
  );
}
