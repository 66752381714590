import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import TeamSection from "./Sections/TeamSection.js";
import FAQSection from "./Sections/FAQSection.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/artists-bg.jpg";
import logo from "assets/img/logo.png";

const useStyles = makeStyles({
  ...styles,
  container: {
    ...styles.container,
    paddingTop: '50px',
  },
  logoFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '40px',
    imageRendering: "pixelated",
    marginRight: '10px',
  },
  brand: {
    margin: '0',
    fontWeight: '500',
  },
});

export default function ArtistsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        brand={<div className={classes.logoFrame}>
          <img src={logo} className={classes.logo}/>
          <h4 className={classes.brand}>Aisthisi</h4>
        </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <TeamSection />
            <FAQSection />
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
