import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as toastrReducer } from 'react-redux-toastr';
import auth from './auth/auth.reducer';

const rootReducer = (history) =>
  combineReducers({
    // Add reducers here like:
    // todos: todos || todos
    router: connectRouter(history),
    toastr: toastrReducer,
    auth: auth,
  });

export default rootReducer;
