import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import Header from 'components/Header/Header.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Footer from 'components/Footer/Footer.js'
import Card from 'components/Card/Card.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import CardFooter from 'components/Card/CardFooter.js'
import CustomInput from 'components/CustomInput/CustomInput.js'

import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import logo from 'assets/img/logo.png'

import image from 'assets/img/artists-bg.jpg'

import { CopyBlock, a11yLight } from 'react-code-blocks'

import { toastr } from 'react-redux-toastr'
import InfoArea from 'components/InfoArea/InfoArea'
import Info from '@material-ui/icons/Info'

import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from 'emailjs-com'

const useStyles = makeStyles({
  ...styles,
  container: {
    ...styles.container,
    paddingTop: '200px',
  },
  logoFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '40px',
    imageRendering: 'pixelated',
    marginRight: '10px',
  },
  brand: {
    margin: '0',
    fontWeight: '500',
  },
})

export default function ShippingPage(props) {
  const auth = useSelector((state) => state.auth)

  const [JSONObject, setJSONObject] = useState(null)
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false)

  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 700)

  const classes = useStyles()
  const { ...rest } = props

  const [shippingData, setShippingData] = useState({
    fullname: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    recaptchaValue: '',
  })

  const [emailSendLoading, setEmailSendLoading] = useState(false)

  const recaptchaValueChange = (value) => {
    setShippingData({
      ...shippingData,
      recaptchaValue: value,
    })
  }

  const handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name

    setShippingData({
      ...shippingData,
      [field]: value,
    })
  }

  const generateSignature = () => {
    if (!auth.web3) {
      toastr.error('Not connected', 'Please connect with you wallet.')
      return
    }

    const deliveryAddress =
      shippingData.fullname +
      '\r\n' +
      shippingData.address +
      '\r\n' +
      shippingData.zip +
      '\r\n' +
      shippingData.city +
      '\r\n' +
      shippingData.country +
      '\r\n' +
      shippingData.phone +
      '\r\n' +
      shippingData.email

    auth.web3.eth.personal.sign(
      deliveryAddress,
      auth.eth_address,
      (error, signature) => {
        if (error) return

        auth.web3.eth.personal.ecRecover(
          deliveryAddress,
          signature,
          (_, address) => {
            if (address.toLowerCase() === auth.eth_address.toLowerCase()) {
              let buff = Buffer.from(
                JSON.stringify({
                  deliveryAddress: deliveryAddress,
                  signedMessage: signature,
                }),
              )
              let base64data = buff.toString('base64')
              
              setEmailSendLoading(true)
              let emailData = {
                'g-recaptcha-response': shippingData.recaptchaValue,
                to: 'shipping@aisthisi.art',
                from_name: shippingData.fullname,
                to_name: 'Aisthisi',
                message: base64data,
              }

              emailjs
                .send(
                  'service_msqvy2m',
                  'template_w0cpgtt',
                  emailData,
                  'user_wiDgUpRrGEhnSxdVDfBaJ',
                )
                .then(
                  (result) => {
                    console.log(result.text)
                    setEmailSentSuccessfully(true)

                    setJSONObject(base64data)
                  },
                  (error) => {
                    console.log(error.text)
                    setEmailSentSuccessfully(false)

                    setJSONObject(base64data)
                  },
                )
            }
          },
        )
      },
    )
  }

  const resetProcess = () => {
    setJSONObject(null)
    setShippingData({
      fullname: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      phone: '',
      email: '',
    })
    setEmailSendLoading(false)
  }

  return (
    <div>
      <Header
        color="transparent"
        brand={
          <div className={classes.logoFrame}>
            <img src={logo} className={classes.logo} />
            <h4 className={classes.brand}>Aisthisi</h4>
          </div>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white',
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.customMain}>
              <Card className={classes[cardAnimaton]}>
                {!JSONObject && (
                  <form className={classes.form}>
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h3>
                        <b>Shipping</b>
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Full Name"
                        id="fullname"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'fullname',
                        }}
                      />
                      <CustomInput
                        labelText="Address"
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'address',
                        }}
                      />
                      <CustomInput
                        labelText="Zip code"
                        id="zip"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'zip',
                        }}
                      />
                      <CustomInput
                        labelText="City"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'city',
                        }}
                      />
                      <CustomInput
                        labelText="Country"
                        id="country"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'country',
                        }}
                      />
                      <CustomInput
                        labelText="Phone"
                        id="phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'phone',
                        }}
                      />
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          onChange: handleChange,
                          name: 'email',
                        }}
                      />

                      <ReCAPTCHA
                        sitekey="6LfGEOkbAAAAAE99xw5-JHKWKJepGofpJr5GAFeo"
                        onChange={recaptchaValueChange}
                      />
                      <InfoArea
                        icon={Info}
                        iconColor="info"
                        description="All fields are required."
                        title=""
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        color="info"
                        size="lg"
                        onClick={() => generateSignature()}
                        disabled={
                          !shippingData.fullname ||
                          !shippingData.address ||
                          !shippingData.zip ||
                          !shippingData.city ||
                          !shippingData.country ||
                          !shippingData.phone ||
                          !shippingData.email ||
                          !shippingData.recaptchaValue ||
                          emailSendLoading
                        }
                      >
                        Generate Signature
                      </Button>
                    </CardFooter>
                  </form>
                )}
                {JSONObject && !emailSentSuccessfully && (
                  <form className={classes.form}>
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h3>
                        <b>Congrats!</b>
                      </h3>
                      <p>
                        You successfully signed the data below. Please finish
                        the following steps in order to complete the process.
                      </p>
                    </CardHeader>
                    <CardBody>
                      <h5>1. Copy data</h5>
                      <CopyBlock
                        text={JSON.stringify(JSONObject)}
                        language={'json'}
                        showLineNumbers={false}
                        startingLineNumber={10}
                        theme={a11yLight}
                      />
                      <h5>2. Send us an email</h5>
                      <p>
                        Send the copied data via email at{' '}
                        <a href="mailto:shipping@aisthisi.art">
                          shipping@aisthisi.art
                        </a>
                      </p>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        color="info"
                        size="lg"
                        onClick={() => resetProcess()}
                      >
                        I am done
                      </Button>
                    </CardFooter>
                  </form>
                )}
                {JSONObject && emailSentSuccessfully && (
                  <form className={classes.form}>
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h3>
                        <b>Congrats!</b>
                      </h3>
                      <p>We received your Shipping Request.</p>
                    </CardHeader>
                    <CardBody>
                      <h5>What's next?</h5>
                      <p>
                        We received your request. The following data was sent to{' '}
                        <a href="mailto:shipping@aisthisi.art">
                          shipping@aisthisi.art
                        </a>{' '}
                        automatically. We will be in touch with you shortly!
                      </p>
                      <CopyBlock
                        text={JSON.stringify(JSONObject)}
                        language={'json'}
                        showLineNumbers={false}
                        startingLineNumber={10}
                        theme={a11yLight}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        color="info"
                        size="lg"
                        onClick={() => resetProcess()}
                      >
                        I am done
                      </Button>
                    </CardFooter>
                  </form>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  )
}
