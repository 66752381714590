import { put, takeLatest, call } from "@redux-saga/core/effects";
import { connectWalletError, connectWalletSuccess, CONNECT_WALLET_PENDING, logoutWallet, LOGOUT_WALLET_PENDING } from "./auth.action";
import { toastr } from 'react-redux-toastr';
import { connectToWallet } from './auth.helpers';
import { storeUserWallet } from "lib/helpers";
import { removeUserWallet } from "lib/helpers";
import { tokenABI } from "lib/abi";

function* connectWallet(action) {
    const { wallet_type } = action;

    const { error, sdk, provider, web3 } = yield call(connectToWallet, wallet_type);

    if (error) {
        toastr.error('Connect Error', error);
        yield put(connectWalletError(wallet_type, error));
        return;
    }

    let eth_address_current = '';

    const address = yield web3.eth.getAccounts();
    if (address && address.length > 0) {
        eth_address_current = address[0];
    }

    const networkId = yield web3.eth.net.getId()

    const tokenAddress = (
        process.env.REACT_APP_TOKEN_ADDRESS || ""
    ).toLowerCase();

    const token_instance = new web3.eth.Contract(
        tokenABI,
        tokenAddress
    );

    // toastr.success('Welcome!', 'Connected successfully via ' + wallet_type + '.');
    yield call(storeUserWallet, wallet_type, eth_address_current);
    yield put(connectWalletSuccess(wallet_type, eth_address_current, web3, provider, sdk, token_instance, networkId));
}

function* logoutWalletNow() {
    yield call(removeUserWallet);
    yield put(logoutWallet());
}

export function* authSagas() {
    yield takeLatest(CONNECT_WALLET_PENDING, connectWallet);
    yield takeLatest(LOGOUT_WALLET_PENDING, logoutWalletNow);
}
