export const storeUserWallet = async (wallet_type, eth_address) => {
    try {
        localStorage.setItem('user', JSON.stringify(
            {
                wallet_type,
                eth_address
            }
        ));
    } catch (err) {
        console.log('Error setting token: ' + err);
    }
};
  
export const removeUserWallet = async () => {
    try {
        localStorage.removeItem('user');
    } catch (err) {
        console.log('Error removing token: ' + err);
    }
};