import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import ImageLoader from "components/ImageLoader/ImageLoader";
import Fade from '@material-ui/core/Fade';
import { Image } from 'react-image-and-background-image-fade';


import Carousel from 'react-material-ui-carousel'

import styles from "assets/jss/material-kit-react/views/landingPageSections/collectionStyle.js";

import aisthisi1 from "assets/img/nfts/aisthisi-1.png";
import aisthisi2 from "assets/img/nfts/aisthisi-2.png";
import aisthisi3 from "assets/img/nfts/aisthisi-3.gif";
import aisthisi4 from "assets/img/nfts/aisthisi-4.gif";
import aisthisi5 from "assets/img/nfts/aisthisi-5.gif";
import aisthisi6 from "assets/img/nfts/aisthisi-6.gif";
import aisthisi7 from "assets/img/nfts/aisthisi-7.gif";
import aisthisi8 from "assets/img/nfts/aisthisi-8.png";
import aisthisi9 from "assets/img/nfts/aisthisi-9.png";
import aisthisi10 from "assets/img/nfts/aisthisi-10.png";
import aisthisi11 from "assets/img/nfts/aisthisi-11.gif";

const useStyles = makeStyles({
  ...styles,
  section: {
    ...styles.section,
    color: '#FFF !important',
  },
  title: {
    ...styles.title,
    color: '#FFF !important',
  },
  cardBody: {
    ...styles.cardBody,
    color: '#FFF !important',
    zIndex: '1',
  },
  cardTitle: {
    ...styles.cardTitle,
    color: '#FFF !important',
    zIndex: '1',
    textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
  },
  smallTitle: {
    ...styles.smallTitle,
    color: '#FFF !important',
    zIndex: '1',
  },
  description: {
    ...styles.description,
    color: '#FFF !important',
    zIndex: '1',
  },
  customCard: {
    borderRadius: '15px',
    boxShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.2)',
    fontSize: '2rem',
    overflow: 'hidden',
    position: 'relative',
    padding: '30px',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '700px',
    minHeight: '557px',
    backgroundSize: 'cover',
    '@media (max-width: 600px)': {
      width: '375px',
    },
    '&:before': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(5px) saturate(100%) contrast(100%) brightness(100%)',
      content: "''",
      height: '100%',
      position: 'absolute',
      width: '100%',
      left: '0',
      top: '0',
    }
  },
});

const carouselIndex = () => {
  let today = new Date();
  if (today.getTime() < new Date('2021-08-18T17:00:00+00:00')) {
    return 0;
  } else if (today.getTime() < new Date('2021-08-22T17:00:00+00:00')) {
    return 1;
  } else if (today.getTime() < new Date('2021-08-27T17:00:00+00:00')) {
    return 2;
  } else if (today.getTime() < new Date('2021-09-01T17:00:00+00:00')) {
    return 3;
  } else if (today.getTime() < new Date('2021-09-06T17:00:00+00:00')) {
    return 4;
  } else if (today.getTime() < new Date('2021-09-11T17:00:00+00:00')) {
    return 5;
  } else if (today.getTime() < new Date('2021-09-16T17:00:00+00:00')) {
    return 6;
  } else if (today.getTime() < new Date('2021-09-21T17:00:00+00:00')) {
    return 7;
  } else if (today.getTime() < new Date('2021-09-26T17:00:00+00:00')) {
    return 8;
  } else if (today.getTime() < new Date('2021-10-01T17:00:00+00:00')) {
    return 9;
  } 
}

const itemStatus = (index) => {
  return index < carouselIndex() ? 'SOLD' : (index == carouselIndex() ? 'AUCTION LIVE' : 'AUCTION UPCOMING');
}

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <Card plain className={classes.customCard} >
        <Carousel animation="fade" autoPlay={false} index={carouselIndex()} swipe={true} navButtonsAlwaysVisible={true}>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #1</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi1} alt="aisthisi1" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: MajorArt</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(0)}</small>
              <br />
              <small className={classes.smallTitle}>Aug. 13</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:1?tab=bids"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>

          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #2</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi2} alt="aisthisi2" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: Tashi Fay</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(1)}</small>
              <br />
              <small className={classes.smallTitle}>Aug. 18</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:2?tab=bids"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #3</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi3} alt="aisthisi3" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: Stephan Weixler</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(2)}</small>
              <br />
              <small className={classes.smallTitle}>Aug. 23</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:3?tab=bids"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #4</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi4} alt="aisthisi4" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: Will Benedict</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(3)}</small>
              <br />
              <small className={classes.smallTitle}>Aug. 28</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:4?tab=bids"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #5</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi5} alt="aisthisi5" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: Ania Shestakova</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(4)}</small>
              <br />
              <small className={classes.smallTitle}>Sept. 2</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:5?tab=bids"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #6</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi6} alt="aisthisi6" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: David Wojtowycz</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(5)}</small>
              <br />
              <small className={classes.smallTitle}>Sept. 7</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:6?tab=details"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #7</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi7} alt="aisthisi7" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: DreamcastleArt</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(6)}</small>
              <br />
              <small className={classes.smallTitle}>Sept. 12</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:7?tab=details"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #8</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi8} alt="aisthisi8" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: RelativeBilanz</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(7)}</small>
              <br />
              <small className={classes.smallTitle}>Sept. 17</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:8?tab=details"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #9</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi9} alt="aisthisi9" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: Antonio Ciutto</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(8)}</small>
              <br />
              <small className={classes.smallTitle}>Sept. 22</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:9?tab=details"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
          <div>
            <h2 className={classes.cardTitle}>
              Aisthisi #10</h2>
            <Fade in={true} timeout={3500}>
              <GridItem xs={10} sm={10} md={6} className={classes.itemGrid}>
                <img src={aisthisi10} alt="aisthisi10" className={imageClasses} style={{ height: "200px" }} />
              </GridItem>
            </Fade>
            <h3 className={classes.cardTitle}>
              <small className={classes.smallTitle}>Artist: Amanda Åkerman</small>
              <br />
              <small className={classes.smallTitle}>{itemStatus(9)}</small>
              <br />
              <small className={classes.smallTitle}>Sept. 27</small>
            </h3>
            <div className={classes.centered}>
              <Button
                color="success"
                className={classes.margin5}
                href="https://rarible.com/token/0x0a093d230ba7845bca0898851b093b8b19bc1ae1:10?tab=details"
                target="_blank"
              >
                VIEW NOW
              </Button>
            </div>
          </div>
        </Carousel>
      </Card>
    </div>
  );
}
